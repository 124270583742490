@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;600;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  /* text-transform: capitalize; */
  caret-color: transparent;
  scrollbar-width: 0px;
}
input:focus,textarea:focus{
  caret-color: black;
}
*::-webkit-scrollbar {
  /* display: none; */
}

body {
  background-color: white !important;
}


* {
  font-family: 'League Spartan', sans-serif;

}

body,
html,
#root {
  height: 100%;
  width: 100%;
}